<template>
  <!-- eslint-disable -->
  <div>
    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <b-card>
        <form-wizard
          color="#7367F0"
          :title="null"
          :subtitle="null"
          next-button-text="Siguiente"
          back-button-text="Atrás"
          finish-button-text="Finalizar"
          class="steps-transparent mb-3"
          ref="wizard"
          
        >
          <!-- account detail tab -->
          <tab-content
            title="Establecer Rest."
            icon="feather icon-folder"
            :lazy="true"
            :before-change="validEstablecer"
          >
            <establecer
              @selectSpe="selectSpe"
              @lead="lead"
              :edit="true"
              :project_id="dataItems.project_id"
              :view="true"
              ref="establecer"
            />
          </tab-content>

          <!-- personal details -->
          <tab-content
            title="Datos Rest."
            icon="feather icon-clipboard"
            :before-change="validDatos"
          >
            <datos
              :leadTime="leadTime"
              :spe_id="id_espe"
              :edit="true"
              :project_id="dataItems.project_id"
              @leadtimeModificado="handleLeadtimeModified"
              :view="true"
              ref="datos"
            />
          </tab-content>

          <!-- address -->
          <tab-content title="Dueño Rest." icon="feather icon-user" :before-change="validDueno">
            <dueno :edit="true" :view="true" ref="dueno"/>
          </tab-content>

          <!-- social link -->
          <tab-content
            title="Definición Fechas"
            icon="feather icon-calendar"
            :before-change="validDefinicion"
          >
            <definicion :edit="true" :view="true" ref="definicion" />
          </tab-content>

          <tab-content title="Archivos" icon="feather icon-upload">
            <archivos :edit="true" :view="true" ref="archivos" />
          </tab-content>
          <template slot="footer" slot-scope="props">
            <b-row>
              <b-col cols="3">
                <div class="wizard-footer-left justify-content-between">
                  <wizard-button
                    :style="props.fillButtonStyle"
                    class="btn btn-danger"
                    @click.native="cancel()"
                    >Volver</wizard-button
                  >
                </div>
              </b-col>
              <b-col cols="4" class="d-flex justify-content-center">
                <!-- <wizard-button
                  v-if="!props.isLastStep"
                  :style="props.fillButtonStyle"
                  class="btn btn-success"
                  @click.native="saveData(props.activeTabIndex)"
                  >Guardar</wizard-button
                > -->
              </b-col>
              <b-col cols="5">
                <div class="wizard-footer-right">
                  <wizard-button
                    v-if="props.activeTabIndex > 0"
                    :style="props.fillButtonStyle"
                    class="mr-2"
                    @click.native="props.prevTab()"
                    >Atras</wizard-button
                  >
                  <wizard-button
                    v-if="!props.isLastStep"
                    @click.native="props.nextTab()"
                    class="wizard-footer-right"
                    :style="props.fillButtonStyle"
                    >Siguiente</wizard-button
                  >

                  <!-- <wizard-button
                    v-else
                    @click.native="saveAll()"
                    class="wizard-footer-right finish-button"
                    :style="props.fillButtonStyle"
                    >{{ props.isLastStep ? 'Finalizar' : 'Siguiente' }}</wizard-button
                  > -->
                </div>
              </b-col>
            </b-row>
          </template>
        </form-wizard>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue'
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { BootstrapVue, BootstrapVueIcons, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import establecer from './steps/establecer.vue'
import datos from './steps/datos.vue'
import dueno from './steps/dueno.vue'
import definicion from './steps/definicion.vue'
import archivos from './steps/archivos.vue'
import RestrictionsService from '@/services/RestrictionsService'
import moment from 'moment'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  data() {
    return {
      tabIndex: 0,
      id_espe: null,
      leadTime: '',
      showLoading: false,
      dataItems: {
        code: '',
        description: '',
        detail: '',
        dependency: '',
        leadtime: '',
        leadtime_modified: '',
        leadtime_comment: '',
        priority: '',
        follow_up_type: '',
        status: '',
        type: '',
        date_identified: null,
        date_required: null,
        date_commited: null,
        date_released: null,
        time_anticipation: null,
        project_id: null,
        speciality_id: null,
        activity_id: null,
        location_1_id: null,
        location_2_id: null,
        location_3_id: null,
        location_4_id: null,
        category_id: null,
        sub_category_id: null,
        owner_id: null,
        co_owner_id: null,
        user_id: null
      },
      userData: JSON.parse(localStorage.getItem('userData'))
    }
  },
  components: {
    establecer,
    datos,
    archivos,
    definicion,
    dueno,
    FormWizard,
    WizardButton,
    TabContent
  },
  mounted() {
    console.log('mounted del edit')
    this.getData()
    var wizard = this.$refs.wizard
    wizard.activateAll()
    this.dataItems.user_id = this.userData.id
  },
  methods: {
    handleLeadtimeModified(nuevoValor) {
      // Este método manejará el evento cuando el valor de leadtime se modifique en el componente datos
      // Actualiza el valor de leadtime en el componente definicion
      this.$refs.definicion.items.leadtime = nuevoValor;
      console.log('Leadtime actualizado en Definicion:', nuevoValor);
    },
    selectSpe(id) {
      this.$refs.datos.setDat(id)
      this.$refs.datos.resetForm()
    },
    lead(led) {
      console.log('activa el lead?')
      this.$refs.datos.setLead(led)
    },
    cancel() {
      this.$router.push('/restricciones')
    },
    async getData() {
      this.showLoading = true
      const getRestri = await RestrictionsService.getRestriccionById(
        this.$route.params.id,
        this.$store
      )
      console.log('getRestriccion edit', getRestri)
      if (getRestri.status) {
        //DATAITEMS
        // this.$refs.establecer.getData()
        this.dataItems = {
          code: getRestri.data.code,
          description: getRestri.data.description,
          detail: getRestri.data.detail,
          dependency: getRestri.data.dependency,
          leadtime: getRestri.data.leadtime,
          leadtime_modified: getRestri.data.leadtime_modified,
          leadtime_comment: getRestri.data.leadtime_comment,
          priority: getRestri.data.priority,
          follow_up_type: getRestri.data.follow_up_type,
          status: getRestri.data.status,
          type: getRestri.data.type,
          date_identified: getRestri.data.date_identified,
          date_required: getRestri.data.date_required,
          date_commited: getRestri.data.date_commited,
          date_released: getRestri.data.date_released,
          time_anticipation: getRestri.data.time_anticipation,
          project_id: getRestri.data.project.id,
          area_id :  getRestri.data.owner.area_id,
          speciality_id: getRestri.data.speciality != null ? getRestri.data.speciality.id : null,
          activity_id: getRestri.data.activity != null ? getRestri.data.activity.id : null,
          location_1_id: getRestri.data.location_1 != null ? getRestri.data.location_1.id : null,
          location_2_id: getRestri.data.location_2 != null ? getRestri.data.location_2.id : null,
          location_3_id: getRestri.data.location_3 != null ? getRestri.data.location_3.id : null,
          location_4_id: getRestri.data.location_4 != null ? getRestri.data.location_4.id : null,
          category_id: getRestri.data.category != null ? getRestri.data.category.id : null,
          sub_category_id:
            getRestri.data.sub_category != null ? getRestri.data.sub_category.id : null,
          owner_id: getRestri.data.owner != null ? getRestri.data.owner.id : null,
          co_owner_id: getRestri.data.co_owner != null ? getRestri.data.co_owner.id : null
        }
        this.$refs.establecer.getData()
        this.$refs.establecer.project = getRestri.data.project
        this.$refs.establecer.items.type = getRestri.data.type
        if (getRestri.data.type === 'campo') {
          this.$refs.establecer.items.type = false;
        } else {
          this.$refs.establecer.items.type = true;
        }
        console.log("TIPOOOOO", this.$refs.establecer.items.type)
        this.$refs.establecer.items.code = getRestri.data.code

        this.$refs.establecer.items.description = getRestri.data.description

        this.$refs.establecer.items.detail = getRestri.data.detail

        this.$refs.establecer.items.speciality_id =
          getRestri.data.speciality != null ? getRestri.data.speciality.id : null

        this.$refs.establecer.items.priority = getRestri.data.priority

        this.$refs.establecer.items.category_id =
          getRestri.data.category != null ? getRestri.data.category.id : null

        // if (getRestri.data.category != null) {
        //   this.$refs.establecer.changeCate(getRestri.data.category.id)
        // }
        // if (getRestri.data.sub_category != null) {
        //   this.$refs.establecer.changeSubCate(getRestri.data.sub_category.id)
        // }
        this.$refs.establecer.items.sub_category_id =
          getRestri.data.sub_category != null ? getRestri.data.sub_category.id : null

        console.log('this.$refs.establecer.items', this.$refs.establecer.items)

        //DATOS
        // await new Promise((r) => setTimeout(r, 200))
        this.$refs.datos.getData()
        this.$refs.datos.project = getRestri.data.project
        this.$refs.datos.items.leadtime = getRestri.data.leadtime
        this.$refs.datos.items.leadtime_modified = getRestri.data.leadtime_modified
        this.$refs.datos.items.leadtime_comment = getRestri.data.leadtime_comment
        this.$refs.datos.items.activity_id =
          getRestri.data.activity != null ? getRestri.data.activity.id : null
        // if (getRestri.data.activity != null) {
        //   this.$refs.datos.selectActivity(getRestri.data.activity.id)
        // }
        this.$refs.datos.items.speciality_id =
          getRestri.data.speciality != null ? getRestri.data.speciality.id : null

        // if (getRestri.data.location_1 != null) {
        //   this.$refs.datos.changeLocal(getRestri.data.location_1.id, 1)
        // }
        this.$refs.datos.items.location_1_id =
          getRestri.data.location_1 != null ? getRestri.data.location_1.id : null

        // if (getRestri.data.location_2 != null) {
        //   this.$refs.datos.changeLocal(getRestri.data.location_2.id, 2)
        // }
        this.$refs.datos.items.location_2_id =
          getRestri.data.location_2 != null ? getRestri.data.location_2.id : null

        // if (getRestri.data.location_3 != null) {
        //   this.$refs.datos.changeLocal(getRestri.data.location_3.id, 3)
        // }
        this.$refs.datos.items.location_3_id =
          getRestri.data.location_3 != null ? getRestri.data.location_3.id : null

        this.$refs.datos.items.location_4_id =
          getRestri.data.location_4 != null ? getRestri.data.location_4.id : null

        
        this.$refs.dueno.items.area_id =
          getRestri.data.owner != null ? getRestri.data.owner.area_id : null
        //DUEÑO
        // await new Promise((r) => setTimeout(r, 200))
        this.$refs.dueno.items.owner_id =
          getRestri.data.owner != null ? getRestri.data.owner.id : null
        // if (getRestri.data.owner) {
        //   this.$refs.dueno.changeDueno(getRestri.data.owner.id)
        // }
        this.$refs.dueno.items.co_owner_id =
          getRestri.data.co_owner != null ? getRestri.data.co_owner.id : null

        // if (getRestri.data.co_owner) {
        //   this.$refs.dueno.changeCoDueno(getRestri.data.co_owner.id)
        // }
        this.$refs.dueno.items.dependency = getRestri.data.dependency
        this.$refs.dueno.userGone = getRestri.data.userGone
        if (getRestri.data.dependency === 'Interna') {
          this.$refs.dueno.items.dependency = false;
        } else {
          this.$refs.dueno.items.dependency = true;
        }
        this.$refs.dueno.items.follow_up_type = getRestri.data.follow_up_type
        this.$refs.dueno.userGone = getRestri.data.userGone
        this.$refs.dueno.firstOwnerId = getRestri.data.owner.id
        this.$refs.dueno.firstAreaCode = getRestri.data.owner.area.code
        this.$refs.dueno.firstOwnerName = getRestri.data.owner.name
        console.log('this.$refs.dueno.items', this.$refs.dueno.items)

        //FECHAS
        // await new Promise((r) => setTimeout(r, 200))
        if (getRestri.data.date_identified != null) {
          console.log('sub', String(getRestri.data.date_identified).substring(0, 10))
          this.$refs.definicion.items.date_identified = moment(
            String(getRestri.data.date_identified).substring(0, 10)
          ).format('yyyy-MM-DD')
        }
        if (getRestri.data.date_commited != null) {
          this.$refs.definicion.items.date_commited = moment(
            String(getRestri.data.date_commited).substring(0, 10)
          ).format('yyyy-MM-DD')
        }
        if (getRestri.data.date_required != null) {
          this.$refs.definicion.items.date_required = moment(
            String(getRestri.data.date_required).substring(0, 10)
          ).format('yyyy-MM-DD')
        }
        const val = Number(getRestri.data.time_anticipation)
        if (val != NaN) {
          this.$refs.definicion.items.time_anticipation = getRestri.data.time_anticipation
        }
        // 
        if (getRestri.data.date_released != null) {
          this.$refs.definicion.items.date_released = moment(
            String(getRestri.data.date_released).substring(0, 10)
          ).format('yyyy-MM-DD')
        }

        this.$refs.definicion.items.status = getRestri.data.status
      
        //ESTABLECER

        console.log('this.$refs.definicion.items', this.$refs.definicion.items)
      }
      this.showLoading = false
    },
    validEstablecer() {
      console.log('aaa aqui')
      return new Promise((resolve, reject) => {
        // var validate = null
        this.$refs.establecer.$refs.refFormObserver.validate().then((success) => {
          if (success) {
            this.dataItems.code = this.$refs.establecer.items.code
            this.dataItems.description = this.$refs.establecer.items.description
            this.dataItems.detail = this.$refs.establecer.items.detail
            this.dataItems.speciality_id = this.$refs.establecer.items.speciality_id
            this.dataItems.priority = this.$refs.establecer.items.priority
            this.dataItems.category_id = this.$refs.establecer.items.category_id
            this.dataItems.sub_category_id = this.$refs.establecer.items.sub_category_id
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validDatos() {
      console.log('aaa si')
      return new Promise((resolve, reject) => {
        this.$refs.datos.$refs.refFormObserver.validate().then((success) => {
          if (success) {
            this.dataItems.leadtime = String(this.$refs.datos.items.leadtime)
            this.dataItems.leadtime_modified = this.$refs.datos.items.leadtime_modified
            this.dataItems.leadtime_comment = this.$refs.datos.items.leadtime_comment
            this.dataItems.activity_id = this.$refs.datos.items.activity_id
            // this.dataItems.speciality_id = this.$refs.datos.items.speciality_id
            this.dataItems.location_1_id = this.$refs.datos.items.location_1_id
            this.dataItems.location_2_id = this.$refs.datos.items.location_2_id
            this.dataItems.location_3_id = this.$refs.datos.items.location_3_id
            this.dataItems.location_4_id = this.$refs.datos.items.location_4_id
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validDueno() {
      return new Promise((resolve, reject) => {
        this.$refs.dueno.$refs.refFormObserver.validate().then((success) => {
          if (success) {
            this.dataItems.owner_id = this.$refs.dueno.items.owner_id
            this.dataItems.co_owner_id = this.$refs.dueno.items.co_owner_id
            this.dataItems.dependency = this.$refs.dueno.items.dependency
            if (this.$refs.dueno.items.dependency) {
              this.dataItems.dependency = "Externa"
            } else {
              this.dataItems.dependency = "Interna"
            }
            this.dataItems.follow_up_type = this.$refs.dueno.items.follow_up_type
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validDefinicion() {
      console.log('assad')
      console.log('this.$refs.definicion.items', this.$refs.definicion.items)
      return new Promise((resolve, reject) => {
        this.$refs.definicion.$refs.refFormObserver.validate().then((success) => {
          if (success) {
            this.dataItems.date_identified = new Date(this.$refs.definicion.items.date_identified)
            this.dataItems.date_required = new Date(this.$refs.definicion.items.date_required)
            this.dataItems.date_commited = new Date(this.$refs.definicion.items.date_commited)
            this.dataItems.time_anticipation = String(this.$refs.definicion.items.time_anticipation)
            this.dataItems.date_released = new Date(this.$refs.definicion.items.date_released)
            this.dataItems.status = this.$refs.definicion.items.status
            if (this.$refs.establecer.items.type) {
              this.dataItems.type = "critica"
            } else {
              this.dataItems.type = "campo"
            }
            /* this.dataItems.type = this.$refs.definicion.items.type */
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    async saveData(index) {
      this.dataItems.user_id = this.userData.id
      this.showLoading = true
      console.log('items', this.dataItems)
      if (index == 0) {
        const isValida1 = await this.$refs.establecer.$refs.refFormObserver.validate()
        if (isValida1) {
          this.dataItems.code = this.$refs.establecer.items.code
          this.dataItems.description = this.$refs.establecer.items.description
          this.dataItems.detail = this.$refs.establecer.items.detail
          this.dataItems.speciality_id = this.$refs.establecer.items.speciality_id
          this.dataItems.priority = this.$refs.establecer.items.priority
          this.dataItems.category_id = this.$refs.establecer.items.category_id
          this.dataItems.sub_category_id = this.$refs.establecer.items.sub_category_id
          if (this.$refs.establecer.items.type) {
            this.dataItems.type = "critica"
          } else {
            this.dataItems.type = "campo"
          }
          /* this.dataItems.type = this.$refs.establecer.items.type */
          this.confirmSave(this.dataItems)
        }
      }
      if (index == 1) {
        const isValida2 = await this.$refs.datos.$refs.refFormObserver.validate()
        if (isValida2) {
          this.dataItems.leadtime = String(this.$refs.datos.items.leadtime)
          this.dataItems.leadtime_modified = this.$refs.datos.items.leadtime_modified
          this.dataItems.leadtime_comment = this.$refs.datos.items.leadtime_comment
          this.dataItems.activity_id = this.$refs.datos.items.activity_id
          this.dataItems.location_1_id = this.$refs.datos.items.location_1_id
          this.dataItems.location_2_id = this.$refs.datos.items.location_2_id
          this.dataItems.location_3_id = this.$refs.datos.items.location_3_id
          this.dataItems.location_4_id = this.$refs.datos.items.location_4_id
          this.confirmSave(this.dataItems)
        }
      }
      if (index == 2) {
        const isValida3 = await this.$refs.dueno.$refs.refFormObserver.validate()
        if (isValida3) {
          this.dataItems.owner_id = this.$refs.dueno.items.owner_id
          this.dataItems.co_owner_id = this.$refs.dueno.items.co_owner_id
          this.dataItems.dependency = this.$refs.dueno.items.dependency
          if (this.$refs.dueno.items.dependency) {
            this.dataItems.dependency = "Externa"
          } else {
            this.dataItems.dependency = "Interna"
          }
          this.dataItems.follow_up_type = this.$refs.dueno.items.follow_up_type
          this.confirmSave(this.dataItems)
        }
      }
      if (index == 3) {
        const isValida4 = await this.$refs.definicion.$refs.refFormObserver.validate()
        if (isValida4) {
          this.dataItems.date_identified = this.$refs.definicion.items.date_identified
          this.dataItems.date_required = this.$refs.definicion.items.date_required
          this.dataItems.date_commited = this.$refs.definicion.items.date_commited
          this.dataItems.time_anticipation = String(this.$refs.definicion.items.time_anticipation)
          this.dataItems.date_released = this.$refs.definicion.items.date_released
          this.dataItems.status = this.$refs.definicion.items.status         
          this.confirmSave(this.dataItems)
        }
      }
      this.showLoading = false
    },
    async confirmSave(items) {
      console.log({itemsforsaved:items})
      const {area_id,...saved}=items
      const save = await RestrictionsService.updateRestricciones(
        this.$route.params.id,
        saved,
        this.$store
      )
      if (save.status) {
        this.$swal({
          title: 'Actualizado',
          text: 'Los datos han sido registrados con exito.',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      } else {
        this.$swal({
          title: 'Error!',
          text: 'Ocurrió un error al registrar los datos',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      }
    },
    async saveAll() {
      console.log('saveall')
      this.showLoading = true
      const isValida1 = await this.$refs.establecer.$refs.refFormObserver.validate()
      const isValida2 = await this.$refs.datos.$refs.refFormObserver.validate()
      const isValida3 = await this.$refs.dueno.$refs.refFormObserver.validate()
      const isValida4 = await this.$refs.definicion.$refs.refFormObserver.validate()
      if (!isValida1 || !isValida2 || !isValida3 || !isValida4) {
        this.$swal({
          icon: 'warning',
          title: 'Advertencia',
          text: 'Complete los campos requeridos en los formularios presentados.',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      } else {
        this.dataItems.code = this.$refs.establecer.items.code
        this.dataItems.description = this.$refs.establecer.items.description
        this.dataItems.detail = this.$refs.establecer.items.detail
        if (this.$refs.establecer.items.type) {
          this.dataItems.type = "critica"
        } else {
          this.dataItems.type = "campo"
        }
        /* this.dataItems.type = this.$refs.establecer.items.type */
        this.dataItems.speciality_id = this.$refs.establecer.items.speciality_id
        this.dataItems.priority = this.$refs.establecer.items.priority
        this.dataItems.category_id = this.$refs.establecer.items.category_id
        this.dataItems.sub_category_id = this.$refs.establecer.items.sub_category_id
        this.dataItems.leadtime = String(this.$refs.datos.items.leadtime)
        this.dataItems.leadtime_modified = this.$refs.datos.items.leadtime_modified
        this.dataItems.leadtime_comment = this.$refs.datos.items.leadtime_comment
        this.dataItems.activity_id = this.$refs.datos.items.activity_id
        this.dataItems.location_1_id = this.$refs.datos.items.location_1_id
        this.dataItems.location_2_id = this.$refs.datos.items.location_2_id
        this.dataItems.location_3_id = this.$refs.datos.items.location_3_id
        this.dataItems.location_4_id = this.$refs.datos.items.location_4_id
        this.dataItems.owner_id = this.$refs.dueno.items.owner_id
        this.dataItems.co_owner_id = this.$refs.dueno.items.co_owner_id
        this.dataItems.dependency = this.$refs.dueno.items.dependency
        if (this.$refs.dueno.items.dependency) {
          this.dataItems.dependency = "Externa"
        } else {
          this.dataItems.dependency = "Interna"
        }
        this.dataItems.follow_up_type = this.$refs.dueno.items.follow_up_type
        this.dataItems.date_identified = this.$refs.definicion.items.date_identified
        this.dataItems.date_required = this.$refs.definicion.items.date_required
        this.dataItems.date_commited = this.$refs.definicion.items.date_commited
        this.dataItems.time_anticipation = String(this.$refs.definicion.items.time_anticipation)
        this.dataItems.date_released = this.$refs.definicion.items.date_released
        this.dataItems.status = this.$refs.definicion.items.status
        this.dataItems.user_id = this.userData.id
      }
      console.log({dataitemssaved:this.dataItems})
      const {area_id,...itemsforSave}=this.dataItems;
      const save = await RestrictionsService.updateRestricciones(
        this.$route.params.id,
        itemsforSave,
        this.$store
      )
      if (save.status) {
        this.$swal({
          title: 'Actualizado',
          text: 'Los datos han sido registrados con exito.',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
        this.$router.push('/restricciones')
      } else {
        this.$swal({
          title: 'Error!',
          text: 'Ocurrió un error al registrar los datos',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      }
      this.showLoading = false
    }
  }
}
</script>
<style></style>
